@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
	padding: 0;
	margin: 0;
	box-sizing: inherit;
}

body {
	box-sizing: border-box;
	scroll-behavior: smooth;
	color: #4d4d4d;
	/* background-color: #e0e6eb !important; */
}

.font9 {
	font-size: 9px;
}
.font8 {
	font-size: 8px;
}
